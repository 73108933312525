<template>
  <div class=" fx-main mg-2">
    <div class="container">
    <div class="coupon-title">Cadastrar Cupom</div>
      <div class="coupon-form">
        <div id="FormCoupon">
          <div class=" input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="coupon_form_name">Código do Cupom</label>
            <input class="default-border" type="text" id="coupon_form_name" v-model="coupon.code" />
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="coupon_form_description">Descrição</label>
            <textarea
              class="default-border"
              type="text"
              id="coupon_form_description"
              v-model="coupon.description"
            />
          </div>
          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="coupon_form_max_uses">Usos Máximos</label>
            <textarea
              class="default-border"
              type="number"
              id="coupon_form_max_uses"
              v-model="coupon.max_uses"
            />
          </div>

          <div class="input-group fx-main mg-bot-2em">
            <label class="mg-right-2-em" for="coupon_form_applies_for">Descontos Aplicáveis</label>
            <select
              multiple="multiple"
              class="border-radius-10 pd-0 scrollbar-none select-style"
              id="coupon_form_applies_for"
              v-model="coupon.applies_for"
            >
              <option value="ITEMS">Itens do Pedido</option>
              <option value="SHIPPING">Frete do Pedido</option>
            </select>
          </div>
          
          <span class="mg-right-2-em">Valor:</span>
          <money
            v-if="coupon.modifier_type === 'PRICE'"
            class="default-border wd-100"
            type="text"
            v-model="coupon.amount"
            v-bind="money"
          ></money>
          <the-mask
            v-if="coupon.modifier_type === 'PERCENTAGE'"
            v-model="coupon.amount"
            mask="##%"
            type="text"
            :masked="false"
            class="default-border wd-100"
          ></the-mask>
          
          <div class="checkbox-type-product fx-main mg-bot-2em mg-top-1">
            <label>Porcentagem</label>
            <input
              type="radio"
              name="coupon_mod"
              v-model="coupon.modifier_type"
              value="PERCENTAGE"
            />
          </div>
          <div class="checkbox-type-product fx-main mg-bot-2em">
            <label>Preço</label>
            <input
              type="radio"
              name="coupon_mod"
              v-model="coupon.modifier_type"
              value="PRICE"
            />
          </div>

          <div class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em" for="coupon_form_published">Utilizável</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="coupon_form_published"
                v-model="coupon.published"
                :true-value="true"
                :false-value="false"
              />
              <label for="coupon_form_published" class="switch__label"></label>
            </div>
          </div>

          <div class="checkbox-type-product input-group fx-main mg-bot-2em fx-a-i-cent">
            <span class="mg-right-2-em" for="coupon_form_cumulative">Cumulativo</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="coupon_form_cumulative"
                v-model="coupon.cumulative"
                :true-value="true"
                :false-value="false"
              />
              <label for="coupon_form_cumulative" class="switch__label"></label>
            </div>
          </div>

          <div class="input-group fx-main mg-bot-2em">
            <button class="btn clickable" v-on:click="registerCoupon">Cadastrar Cupom</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</template>

<style scoped>

.pd-0 {
  padding: 0 2px;
}
.border-radius-10 {
  border-radius: 10px;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.select-style {
  height: 120px;
  font-weight: bold;
  font-size: .9em;
}

.select-style option {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}
</style>

<script>
import Coupon from "@/services/coupon.js";
import util from "@/services/util";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";

function generate_data() {
  return {
    coupon: {
      code: "",
      description: "",
      applies_for: ["ITEMS", "SHIPPING"],
      due_date: null,
      published: false,
      amount: "",
      cumulative: true,
      modifier_type: "PERCENTAGE",
      max_uses: 0,
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false /* doesn't work with directive */
    }
  };
}

export default {
  name: "FormCoupon",
  data: generate_data,
  methods: {
    registerCoupon: function() {
      var coupon = JSON.parse(JSON.stringify(this.coupon));
      if(coupon.modifier_type === "PRICE") {
        coupon.amount = Number(String(coupon.amount)) * 100;
      }
      else {
        coupon.amount = Number(String(coupon.amount).replace(/\D/g, ""));
      }
      if(!coupon.max_uses || coupon.max_uses <= 0) {
        coupon.max_uses = null;
      }
      Coupon.create(coupon).then(
        serviceReturn => {
          this.$notify({
            type: "success",
            title: "Cupom Cadastrado",
            text: "Cupom Cadastrado com Sucesso!"
          });
          Object.assign(this.$data, generate_data());
        },
        errorResponse => {
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Cadastrar Cupom",
            text: util.stringifyAxiosError(errorResponse)
          });
        }
      );
    }
  },
  components: {
    money: Money,
    "the-mask": TheMask
  }
};
</script>